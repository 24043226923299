import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/fp/debounce';
import classNames from 'classnames';

import addEventListener from '../../utils/addEventListener';

const RESIZE_THROTTELING = 200;

export class ApplicationLayoutBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
        };

        this.handleScroll = debounce(RESIZE_THROTTELING)(this.handleScroll.bind(this));
        this.handleToTop = this.handleToTop.bind(this);
    }

    componentDidMount() {
        if (this.moduleContentRef) {
            this.onScrollListener = addEventListener(this.moduleContentRef, 'scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        this.onScrollListener.remove();
    }

    handleScroll(event) {
        if (event && event.srcElement) {
            this.setState({ offset: event.srcElement.scrollTop });
        }
    }

    handleToTop() {
        if (!this.moduleContentRef) {
            return;
        }
        const currentScroll = this.moduleContentRef.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(this.handleToTop);
            this.moduleContentRef.scrollTo(0, currentScroll - currentScroll / 5);
            this.setState({ offset: currentScroll });
        }
    }

    render() {
        const { className, innerClassName, forceScrollbar, enableScrollToTop, banner, children } = this.props;
        const { offset } = this.state;

        const classes = classNames('ApplicationLayoutBody', className);
        const innerClasses = classNames(
            'module-content',
            forceScrollbar && 'overflow-y-scroll',
            innerClassName && innerClassName
        );

        const offsetThreshold = window.innerHeight * 0.1;

        const scrollToTopClasses = classNames('scroll-to-top', offset > offsetThreshold && 'in');

        return (
            <div className={classes}>
                {banner && banner}

                <div className={innerClasses} ref={node => (this.moduleContentRef = node)}>
                    {children}
                </div>

                {enableScrollToTop && (
                    <span className={scrollToTopClasses}>
                        <button className={'btn btn-icon-only'} onClick={this.handleToTop}>
                            <span className={'rioglyph rioglyph-arrow-up'} />
                        </button>
                    </span>
                )}
            </div>
        );
    }
}

ApplicationLayoutBody.defaultProps = {
    className: '',
    enableScrollToTop: true,
    forceScrollbar: false,
};

ApplicationLayoutBody.propTypes = {
    className: PropTypes.string,
    enableScrollToTop: PropTypes.bool,
    forceScrollbar: PropTypes.bool,
    innerClassName: PropTypes.string,
    banner: PropTypes.node,
};

export default ApplicationLayoutBody;
