import React from 'react';
import PropTypes from 'prop-types';

import { logDeprecatedWarnings } from '../../utils/logDeprecatedWarnings';
import Dialog from './Dialog';
import ReleaseNotes from '../releaseNotes/ReleaseNotes';

const deprecatedProps = [
    {
        prop: 'translations',
        replacement: 'moduleName, whatsNewTitle and closeButtonText',
        url: 'components/dialogs',
    },
    {
        prop: 'releaseNotes',
        replacement: 'translatedReleaseNotes',
        url: 'components/dialogs',
    },
    {
        prop: 'translationKey',
        replacement: 'translatedReleaseNotes',
        url: 'components/dialogs',
    },
];

const setReleaseNotesItem = props => {
    window.localStorage.setItem(props.RELEASE_NOTES_ITEM, props.currentVersion);
    props.setShowReleaseNotes(false);
};

const getModuleName = (moduleNameTranslation, whatsNewTranslation) => (
    <span>{moduleNameTranslation ? moduleNameTranslation : whatsNewTranslation}</span>
);

const getBody = props => {
    const { translatedReleaseNotes, releaseNotes, translationKey } = props;
    const notes = translatedReleaseNotes ? translatedReleaseNotes : releaseNotes[translationKey];
    return <ReleaseNotes releaseNotes={notes} />;
};

const getFooter = props => {
    let closeTranslation = props.closeButtonText;
    if (!closeTranslation && props.translations) {
        closeTranslation = props.translations['close'];
    }

    return (
        <button
            data-component={'CloseButton'}
            type={'button'}
            className={'btn btn-default'}
            onClick={() => setReleaseNotesItem(props)}
        >
            {closeTranslation ? closeTranslation : 'Close'}
        </button>
    );
};

const ReleaseNotesDialog = props => {
    logDeprecatedWarnings(deprecatedProps, props, 'ReleaseNotesDialog', '0.13.0');

    let moduleNameKeyTranslation = false;
    if (props.translations) {
        moduleNameKeyTranslation = props.translations['module.name']
            ? props.translations['module.name']
            : props.translations['moduleName'];
    }

    const moduleNameTranslation = props.moduleName ? props.moduleName : moduleNameKeyTranslation;

    let whatsNewKeyTranslation = '';
    if (props.translations && props.translations['whatsNew']) {
        whatsNewKeyTranslation = props.translations['whatsNew'];
    }

    const whatsNewTranslation = props.whatsNewTitle ? props.whatsNewTitle : whatsNewKeyTranslation;

    return (
        <Dialog
            show={props.showReleaseNotes}
            useOverflow={props.useOverflow}
            title={getModuleName(moduleNameTranslation, whatsNewTranslation)}
            subtitle={moduleNameTranslation ? whatsNewTranslation : undefined}
            body={getBody(props)}
            footer={getFooter(props)}
            onHide={() => setReleaseNotesItem(props)}
        />
    );
};

ReleaseNotesDialog.defaultProps = {
    useOverflow: true,
};

ReleaseNotesDialog.propTypes = {
    showReleaseNotes: PropTypes.bool.isRequired,
    setShowReleaseNotes: PropTypes.func.isRequired,
    translations: PropTypes.object,
    whatsNewTitle: PropTypes.string,
    moduleName: PropTypes.string,
    closeButtonText: PropTypes.string,
    translationKey: PropTypes.string,
    releaseNotes: PropTypes.object,
    translatedReleaseNotes: PropTypes.object,
    currentVersion: PropTypes.string.isRequired,
    RELEASE_NOTES_ITEM: PropTypes.string.isRequired,
    useOverflow: PropTypes.bool,
};

export default ReleaseNotesDialog;
