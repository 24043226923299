import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/fp/throttle';
import ApplicationLayoutHeader from './ApplicationLayoutHeader';
import ApplicationLayoutSidebar from './ApplicationLayoutSidebar';
import ApplicationLayoutBody from './ApplicationLayoutBody';
import ApplicationLayoutFooter from './ApplicationLayoutFooter';

export class ApplicationLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styles: this.calcViewportUnits(),
        };

        this.setViewportUnits = throttle(1000, this.setViewportUnits.bind(this));

        window.addEventListener('orientationchange', this.setViewportUnits);
        window.addEventListener('resize', this.setViewportUnits);
    }

    setViewportUnits() {
        this.setState({
            styles: this.calcViewportUnits(),
        });
    }

    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    calcViewportUnits() {
        const vh = window.innerHeight * 0.01;
        return {
            '--vh': `${vh}px`,
        };
    }

    render() {
        const { className, children } = this.props;
        const { styles } = this.state;

        const classes = classNames('ApplicationLayout', className);

        return (
            <div className={classes} style={styles}>
                {children}
            </div>
        );
    }
}

ApplicationLayout.Header = ApplicationLayoutHeader;
ApplicationLayout.Sidebar = ApplicationLayoutSidebar;
ApplicationLayout.Body = ApplicationLayoutBody;
ApplicationLayout.Footer = ApplicationLayoutFooter;

ApplicationLayout.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayout;
