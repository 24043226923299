/* eslint-disable max-len */

import React from 'react';
import versionJson from './version.json';
const VERSION = versionJson.version;
export { VERSION };

// polyfill for scrollToTop() // Edge <= 15
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import { patchReactForReactBootstrap } from './patchReact';
patchReactForReactBootstrap(React);

import _colors from './style/css/shared/colors.json';
export { _colors as colors };

import { default as _Button } from './common/components/button/Button';
export { _Button as Button };

import { default as _AutoSuggest } from './common/components/autosuggest/AutoSuggest';
export { _AutoSuggest as AutoSuggest };

import { default as _ToggleButton } from './common/components/button/ToggleButton';
export { _ToggleButton as ToggleButton };

import { default as _Checkbox } from './common/components/checkbox/Checkbox';
export { _Checkbox as Checkbox };

import { default as _RadioButton } from './common/components/radiobutton/RadioButton';
export { _RadioButton as RadioButton };

import { default as _Sidebar } from './common/components/sidebar/Sidebar';
export { _Sidebar as Sidebar };

import { default as _AssetTree } from './common/components/assetTree/AssetTree';
export { _AssetTree as AssetTree };

import { default as _TreeCategory } from './common/components/assetTree/TreeCategory';
export { _TreeCategory as TreeCategory };

import { default as _Tree } from './common/components/assetTree/Tree';
export { _Tree as Tree };

import { default as _TreeSearch } from './common/components/assetTree/TreeSearch';
export { _TreeSearch as TreeSearch };

import { default as _TreeSummary } from './common/components/assetTree/TreeSummary';
export { _TreeSummary as TreeSummary };

import { default as _TypeCounter } from './common/components/assetTree/TypeCounter';
export { _TypeCounter as TypeCounter };

import { default as _Dialog } from './common/components/dialog/Dialog';
export { _Dialog as Dialog };

import { default as _ConfirmationDialog } from './common/components/dialog/ConfirmationDialog';
export { _ConfirmationDialog as ConfirmationDialog };

import { default as _DropdownSubmenu } from './common/components/dropdown/DropdownSubmenu';
export { _DropdownSubmenu as DropdownSubmenu };

import { default as _MenuItem } from './common/components/menuItems/MenuItem';
export { _MenuItem as MenuItem };

import { default as _ButtonDropdown } from './common/components/dropdown/ButtonDropdown';
export { _ButtonDropdown as ButtonDropdown };

import { default as _SimpleButtonDropdown } from './common/components/dropdown/SimpleButtonDropdown';
export { _SimpleButtonDropdown as SimpleButtonDropdown };

import { default as _SingleButtonDropdown } from './common/components/dropdown/SingleButtonDropdown';
export { _SingleButtonDropdown as SingleButtonDropdown };

import { default as _SplitButtonDropdown } from './common/components/dropdown/SplitButtonDropdown';
export { _SplitButtonDropdown as SplitButtonDropdown };

import { default as _SaveDialog } from './common/components/dialog/SaveDialog';
export { _SaveDialog as SaveDialog };

import { default as _SplitDialog } from './common/components/dialog/SplitDialog';
export { _SplitDialog as SplitDialog };

import { default as _SimpleDialog } from './common/components/dialog/SimpleDialog';
export { _SimpleDialog as SimpleDialog };

import { default as _InfoDialog } from './common/components/dialog/InfoDialog';
export { _InfoDialog as InfoDialog };

import { default as _MediaDialog } from './common/components/dialog/MediaDialog';
export { _MediaDialog as MediaDialog };

import { default as _TableSettingsDialog } from './common/components/table/TableSettingsDialog';
export { _TableSettingsDialog as TableSettingsDialog };

import { default as _TableViewToggles } from './common/components/table/TableViewToggles';
export { _TableViewToggles as TableViewToggles };

import { default as _TableToolbar } from './common/components/table/TableToolbar';
export { _TableToolbar as TableToolbar };

import { default as _TableSearch } from './common/components/table/TableSearch';
export { _TableSearch as TableSearch };

import { default as _TableCardsSorting } from './common/components/table/TableCardsSorting';
export { _TableCardsSorting as TableCardsSorting };

import { default as _Slider } from './common/components/slider/Slider';
export { _Slider as Slider };

import { default as _RangeSlider } from './common/components/slider/RangeSlider';
export { _RangeSlider as RangeSlider };

import { default as _Spinner } from './common/components/spinner/Spinner';
export { _Spinner as Spinner };

import { default as _SpinnerInfoBox } from './common/components/spinner/SpinnerInfoBox';
export { _SpinnerInfoBox as SpinnerInfoBox };

import { default as _Switch } from './common/components/switch/Switch';
export { _Switch as Switch };

import { default as _NumberInput } from './common/components/numberInput/NumberInput';
export { _NumberInput as NumberInput };

import { default as _NumberControl } from './common/components/numberControl/NumberControl';
export { _NumberControl as NumberControl };

import { default as _Tag } from './common/components/tag/Tag';
export { _Tag as Tag };

import { default as _Collapse } from './common/components/collapse/Collapse';
export { _Collapse as Collapse };

import { default as _Fade } from './common/components/fade/Fade';
export { _Fade as Fade };

import { default as _CollapsibleFilter } from './common/components/filter/CollapsibleFilter';
export { _CollapsibleFilter as CollapsibleFilter };

import { default as _FilterListItem } from './common/components/filter/FilterListItem';
export { _FilterListItem as FilterListItem };

import { default as _ResponsiveVideo } from './common/components/video/ResponsiveVideo';
export { _ResponsiveVideo as ResponsiveVideo };

import { default as _ImagePreloader } from './common/components/preloader/ImagePreloader';
export { _ImagePreloader as ImagePreloader };

import { default as _Resizer } from './common/components/resizer/Resizer';
export { _Resizer as Resizer };

import { default as _ListMenu } from './common/components/listMenu/ListMenu';
export { _ListMenu as ListMenu };

import { default as _Tooltip } from './common/components/tooltip/Tooltip';
export { _Tooltip as Tooltip };

import {
    default as _VehicleCategory,
    CATEGORY_TRUCK as _CATEGORY_TRUCK,
    CATEGORY_BUS as _CATEGORY_BUS,
    CATEGORY_VAN as _CATEGORY_VAN,
} from './common/components/vehicleCategory/VehicleCategory';
export {
    _VehicleCategory as VehicleCategory,
    _CATEGORY_TRUCK as CATEGORY_TRUCK,
    _CATEGORY_BUS as CATEGORY_BUS,
    _CATEGORY_VAN as CATEGORY_VAN,
};

import { default as _DriverName } from './common/components/driverName/DriverName';
export { _DriverName as DriverName };

import {
    default as _VehicleRatingCounter,
    RATING_OK as _RATING_OK,
    RATING_WARNING as _RATING_WARNING,
    RATING_EXCEPTION as _RATING_EXCEPTION,
} from './common/components/vehicleRatingCounter/VehicleRatingCounter';
export {
    _VehicleRatingCounter as VehicleRatingCounter,
    _RATING_OK as RATING_OK,
    _RATING_WARNING as RATING_WARNING,
    _RATING_EXCEPTION as RATING_EXCEPTION,
};

import { default as _Select } from './common/components/selects/Select';
export { _Select as Select };

import { default as _Multiselect } from './common/components/selects/Multiselect';
export { _Multiselect as Multiselect };

import { default as _DatePicker } from './common/components/datepicker/DatePicker';
export { _DatePicker as DatePicker };

import { default as _DateRangePicker } from './common/components/datepicker/DateRangePicker';
export { _DateRangePicker as DateRangePicker };

// TODO: remove it from here as it's in maps
import { default as _SingleMapMarker } from './common/components/mapMarker/SingleMapMarker';
export { _SingleMapMarker as SingleMapMarker };

import { default as _ClusterMapMarker } from './common/components/mapMarker/ClusterMapMarker';
export { _ClusterMapMarker as ClusterMapMarker };

import { default as _VehicleMarker } from './common/components/mapMarker/VehicleMarker';
export { _VehicleMarker as VehicleMarker };

import { default as _MarkerTooltip } from './common/components/mapMarker/MarkerTooltip';
export { _MarkerTooltip as MarkerTooltip };

import { default as _FilePicker } from './common/components/filepicker/FilePicker';
export { _FilePicker as FilePicker };

import { default as _RioBootstrapTable } from './common/components/table/bootstrap/RioBootstrapTable';
import { default as _RioTableHeaderColumn } from './common/components/table/bootstrap/RioTableHeaderColumn';
export { _RioBootstrapTable as RioBootstrapTable, _RioTableHeaderColumn as RioTableHeaderColumn };

import { default as _SteppedProgressBar } from './common/components/steppedProgressBar/SteppedProgressBar';
export { _SteppedProgressBar as SteppedProgressBar };

import { default as _Position } from './common/components/position/Position';
export { _Position as Position };

import { default as _ValueWithHint } from './common/components/valueWithHint/ValueWithHint';
export { _ValueWithHint as ValueWithHint };

import { default as _Notification } from './common/components/notification/Notification';
import { default as _NotificationsContainer } from './common/components/notification/NotificationsContainer';
export { _Notification as Notification, _NotificationsContainer as NotificationsContainer };

import { default as _ApplicationHeader } from './common/components/applicationHeader/ApplicationHeader';
export { _ApplicationHeader as ApplicationHeader };

import { default as _NotFoundState } from './common/components/states/NotFoundState';
export { _NotFoundState as NotFoundState };

import { default as _EmptyState } from './common/components/states/EmptyState';
export { _EmptyState as EmptyState };

import { default as _ErrorState } from './common/components/states/ErrorState';
export { _ErrorState as ErrorState };

import { default as _ForbiddenState } from './common/components/states/ForbiddenState';
export { _ForbiddenState as ForbiddenState };

import { default as _NotBookedState } from './common/components/states/NotBookedState';
export { _NotBookedState as NotBookedState };

import { default as _CustomState } from './common/components/states/CustomState';
export { _CustomState as CustomState };

import {
    SortArrowUp as _SortArrowUp,
    SortArrowDown as _SortArrowDown,
    SortArrows as _SortArrows,
} from './common/components/table/bootstrap/SortArrows';
export { _SortArrowUp as SortArrowUp, _SortArrowDown as SortArrowDown, _SortArrows as SortArrows };

import {
    // SortUtil as _SortUtil,
    SortDirection as _SortDirection,
    sortByProperty as _sortByProperty,
    naturalSortByProperty as _naturalSortByProperty,
} from './common/utils/SortUtil';
export {
    // _SortUtil as SortUtil,
    _SortDirection as SortDirection,
    _sortByProperty as sortByProperty,
    _naturalSortByProperty as naturalSortByProperty,
};

import {
    getSortDirShort as _getSortDirShort,
    parseSorting as _parseSorting,
    mapColumnsSettingsToStrings as _mapColumnsSettingsToStrings,
    parseColumnsSettingsStrings as _parseColumnsSettingsStrings,
} from './common/utils/routeUtils';
export {
    _getSortDirShort as getSortDirShort,
    _parseSorting as parseSorting,
    _mapColumnsSettingsToStrings as mapColumnsSettingsToStrings,
    _parseColumnsSettingsStrings as parseColumnsSettingsStrings,
};

import { hasTouch as _hasTouch } from './common/utils/deviceUtils';
export { _hasTouch as hasTouch };

import { getNewGroupedSelected as _getNewGroupedSelected } from './common/utils/GroupSelectionUtil';
export { _getNewGroupedSelected as getNewGroupedSelected };

import { default as _ExpanderPanel } from './common/components/expander/ExpanderPanel';
export { _ExpanderPanel as ExpanderPanel };

import { default as _ExpanderList } from './common/components/expander/ExpanderList';
export { _ExpanderList as ExpanderList };

import { default as _ClearableInput } from './common/components/clearableInput/ClearableInput';
export { _ClearableInput as ClearableInput };

import { default as _ApplicationLayout } from './common/components/applicationLayout/ApplicationLayout';
export { _ApplicationLayout as ApplicationLayout };

import { default as _ApplicationLayoutBodyBanner } from './common/components/applicationLayout/ApplicationLayoutBodyBanner';
export { _ApplicationLayoutBodyBanner as ApplicationLayoutBodyBanner };

import { default as _ActionBarItem } from './common/components/actionBarItem/ActionBarItem';
export { _ActionBarItem as ActionBarItem };

import { default as _ReleaseNotesDialog } from './common/components/dialog/ReleaseNotesDialog';
export { _ReleaseNotesDialog as ReleaseNotesDialog };

import { default as _ReleaseNotes } from './common/components/releaseNotes/ReleaseNotes';
export { _ReleaseNotes as ReleaseNotes };

import { default as _Teaser } from './common/components/teaser/Teaser';
export { _Teaser as Teaser };

import { default as _TeaserContainer } from './common/components/teaser/TeaserContainer';
export { _TeaserContainer as TeaserContainer };

import { default as _StatusBar } from './common/components/statusBar/StatusBar';
export { _StatusBar as StatusBar };

import { default as _DataTabs } from './common/components/dataTabs/DataTabs';
export { _DataTabs as DataTabs };

import { default as _DataTab } from './common/components/dataTabs/DataTab';
export { _DataTab as DataTab };

import { default as _Activity } from './common/components/activity/Activity';
export { _Activity as Activity };

import { default as _OnboardingTip } from './common/components/onboarding/OnboardingTip';
export { _OnboardingTip as OnboardingTip };

/*
 * Wrapper for react-bootstrap:
 * This wraps all needed react-bootstrap components so the modules themself
 * dont need to import react-bootstrap. This will asure all modules are using the same version.
 *
 * ATTENTION: Please use the actual Bootstrap Markup instead in order to be less dependant on react-bootstrap!
 */
// TODO: remove react-boostrap exports as devs should use HTML Markup with Bootstrap classes
import {
    ButtonToolbar as _ButtonToolbar,
    ButtonGroup as _ButtonGroup,
    DropdownButton as _DropdownButton,
    SplitButton as _SplitButton,
    Modal as _Modal,
    Overlay as _Overlay,
    OverlayTrigger as _OverlayTrigger, // should be replaced by own solution (as this is used by Teams)
    Nav as _Nav,
    NavItem as _NavItem,
    NavDropdown as _NavDropdown,
    Navbar as _Navbar,
    Breadcrumb as _Breadcrumb,
    Tabs as _Tabs, // should be replaced by own solution (as this is used by Teams)
    Tab as _Tab,
    TabContainer as _TabContainer,
    TabContent as _TabContent,
    TabPane as _TabPane,
    Pagination as _Pagination,
    Pager as _Pager,
    Grid as _Grid,
    Row as _Row,
    Col as _Col,
    Clearfix as _Clearfix,
    Jumbotron as _Jumbotron,
    PageHeader as _PageHeader,
    ListGroup as _ListGroup,
    ListGroupItem as _ListGroupItem,
    Table as _Table,
    Panel as _Panel,
    PanelGroup as _PanelGroup,
    Accordion as _Accordion,
    Well as _Well,
    Form as _Form,
    FormGroup as _FormGroup, // should be replaced by own solution (as this is used by Teams)
    FormControl as _FormControl,
    ControlLabel as _ControlLabel,
    InputGroup as _InputGroup,
    Carousel as _Carousel, // should be replaced by own solution (as this is used by Teams)
    Label as _Label,
    Badge as _Badge,
    Alert as _Alert,
    ProgressBar as _ProgressBar,
    //Collapse as _Collapse, // should be replaced by own solution (as this is used by Teams)
    //Fade as _Fade, // should be replaced by own solution (as this is used by Teams)
    //Tooltip as _Tooltip,
    Popover as _Popover,
} from 'react-bootstrap';
export {
    _ButtonToolbar as ButtonToolbar,
    _ButtonGroup as ButtonGroup,
    _DropdownButton as DropdownButton,
    _SplitButton as SplitButton,
    _Modal as Modal,
    _Overlay as Overlay,
    _OverlayTrigger as OverlayTrigger,
    _Nav as Nav,
    _NavItem as NavItem,
    _NavDropdown as NavDropdown,
    _Navbar as Navbar,
    _Breadcrumb as Breadcrumb,
    _Tabs as Tabs,
    _Tab as Tab,
    _TabContainer as TabContainer,
    _TabContent as TabContent,
    _TabPane as TabPane,
    _Pagination as Pagination,
    _Pager as Pager,
    _Grid as Grid,
    _Row as Row,
    _Col as Col,
    _Clearfix as Clearfix,
    _Jumbotron as Jumbotron,
    _PageHeader as PageHeader,
    _ListGroup as ListGroup,
    _ListGroupItem as ListGroupItem,
    _Table as Table,
    _Panel as Panel,
    _PanelGroup as PanelGroup,
    _Accordion as Accordion,
    _Well as Well,
    _Form as Form,
    _FormGroup as FormGroup,
    _FormControl as FormControl,
    _ControlLabel as ControlLabel,
    _InputGroup as InputGroup,
    _Carousel as Carousel,
    _Label as Label,
    _Badge as Badge,
    _Alert as Alert,
    _ProgressBar as ProgressBar,
    //_Collapse as Collapse,
    //_Fade as Fade,
    //_Tooltip as Tooltip,
    _Popover as Popover,
};
