import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getColorMapping = (colorClass = '') => {
    switch (colorClass) {
        case 'color-a':
            return 'bg-map-marker-asset';
        case 'color-b':
            return 'bg-map-marker-event';
        case 'color-c':
            return 'bg-map-marker-poi';
        default:
            return null;
    }
};

const ClusterMapMarker = (props) => {
    const {
        colorClass,
        count,
        exceptionCount,
        markerColor,
        moving,
        warningCount,
        active,
    } = props;

    const classes = classNames(
        active && 'active',
        'rio-map-marker',
        'rio-map-marker-center-center'
    );

    const clusterClasses = classNames(
        'rio-map-cluster',
        colorClass,
        moving && 'moving'
    );

    const baseColor = getColorMapping(colorClass) || markerColor;

    const markerBackgroundColor = active ? 'bg-white' : baseColor;

    const markerBorderColor = baseColor.replace('bg-', 'border-color-');

    const markerTextColor = active ? baseColor.replace('bg-', 'text-color-') : 'text-color-white';

    return (
        <div className={classes}>
            <div className={`rio-map-marker-translate`}>
            <div className={`${clusterClasses} ${markerBackgroundColor} ${markerBorderColor} ${markerTextColor}`}>
                    <div className='rio-map-count'>
                        {count}
                    </div>
                    <div className='rio-map-bubble exception'>
                        {(exceptionCount > 0) && exceptionCount}
                    </div>
                    <div className='rio-map-bubble warning'>
                        {(warningCount > 0) && warningCount}
                    </div>
                </div>
            </div>
        </div>
    );
};

ClusterMapMarker.defaultProps = {
    warningCount: 0,
    exceptionCount: 0,
    active: false,
    markerColor: 'bg-map-marker-asset',
};

ClusterMapMarker.propTypes = {
    count: PropTypes.number,
    warningCount: PropTypes.number,
    exceptionCount: PropTypes.number,
    active: PropTypes.bool,
    moving: PropTypes.bool,
    colorClass: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default ClusterMapMarker;
