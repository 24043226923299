import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
//import logDeprecatedWarnings from '../../utils/logDeprecatedWarnings';

// const deprecatedProps = [{
//     prop: 'arrowOffsetTop',
//     replacement: 'className',
// }, {
//     prop: 'arrowOffsetLeft',
//     replacement: 'className',
// }, {
//     prop: 'positionTop',
//     replacement: 'offsetTop',
// }, {
//     prop: 'positionLeft',
//     replacement: 'offsetLeft',
// }];

const Tooltip = (props) => {
    const {
        placement,
        offsetTop,
        positionTop,
        offsetLeft,
        positionLeft,
        arrowOffsetTop,
        arrowOffsetLeft,
        className,
        textAlignment,
        tooltipStyle,
        style,
        children,
        width,
        ...remainingProps
    } = props;

    const wrapperClasses = classnames(
        'tooltip',
        tooltipStyle && `tooltip-${tooltipStyle}`,
        [placement],
        className
    );

    const outerStyle = {
        top: positionTop || offsetTop,
        left: positionLeft || offsetLeft,
        ...style,
    };

    const innerClasses = classnames(
        'tooltip-inner',
        textAlignment && `text-${textAlignment}`,
        width && `width-${width}`
    );

    const arrowStyle = {
        top: arrowOffsetTop,
        left: arrowOffsetLeft,
    };

    //logDeprecatedWarnings(deprecatedProps, props, 'Tooltip');

    return (
        <div {...remainingProps} role='tooltip' className={wrapperClasses} style={outerStyle}>
            <div className={'tooltip-arrow'} style={arrowStyle} />
            <div className={'tooltip-inner'} className={innerClasses}>
                {children}
            </div>
        </div>
    );
};

Tooltip.PLACEMENT_TOP_LEFT = 'top-left';
Tooltip.PLACEMENT_TOP = 'top';
Tooltip.PLACEMENT_TOP_RIGHT = 'top-right';
Tooltip.PLACEMENT_RIGHT_TOP = 'right-top';
Tooltip.PLACEMENT_RIGHT = 'right';
Tooltip.PLACEMENT_RIGHT_BOTTOM = 'right-bottom';
Tooltip.PLACEMENT_BOTTOM_RIGHT = 'bottom-right';
Tooltip.PLACEMENT_BOTTOM = 'bottom';
Tooltip.PLACEMENT_BOTTOM_LEFT = 'bottom-left';
Tooltip.PLACEMENT_LEFT_BOTTOM = 'left-bottom';
Tooltip.PLACEMENT_LEFT = 'left';
Tooltip.PLACEMENT_LEFT_TOP = 'left-top';

Tooltip.STYLE_DEFAULT = 'default';
Tooltip.STYLE_ONBOARDING = 'onboarding';

Tooltip.defaultProps = {
    placement: Tooltip.PLACEMENT_BOTTOM,
    className: '',
    textAlignment: 'center',
    tooltipStyle: Tooltip.STYLE_DEFAULT,
    offsetTop: null,
    offsetLeft: null,
    width: null,
};

Tooltip.propTypes = {
    placement: PropTypes.oneOf([
        Tooltip.PLACEMENT_TOP_LEFT,
        Tooltip.PLACEMENT_TOP,
        Tooltip.PLACEMENT_TOP_RIGHT,
        Tooltip.PLACEMENT_RIGHT_TOP,
        Tooltip.PLACEMENT_RIGHT,
        Tooltip.PLACEMENT_RIGHT_BOTTOM,
        Tooltip.PLACEMENT_BOTTOM_RIGHT,
        Tooltip.PLACEMENT_BOTTOM,
        Tooltip.PLACEMENT_BOTTOM_LEFT,
        Tooltip.PLACEMENT_LEFT_BOTTOM,
        Tooltip.PLACEMENT_LEFT,
        Tooltip.PLACEMENT_LEFT_TOP,
    ]),
    offsetTop: PropTypes.number,
    offsetLeft: PropTypes.number,
    className: PropTypes.string,
    tooltipStyle: PropTypes.oneOf([Tooltip.STYLE_DEFAULT, Tooltip.STYLE_ONBOARDING]),
    textAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    width: PropTypes.oneOf(['auto', 100, 150, 200, 250, 300, 350, 400, 450, 500]),
};

export default Tooltip;
