import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import compact from 'lodash/fp/compact';
import { BootstrapTable } from 'react-bootstrap-table';
import { sortByProperty } from '../../../utils/SortUtil';
import Checkbox from '../../checkbox/Checkbox';
import TableCardsSorting from '../TableCardsSorting';

const STOPPING_NODES = ['A', 'BUTTON', 'INPUT', 'LABEL'];
const STOPPING_CLASSES = ['btn'];

const removedDeprecatedProps = {
    insertRow: undefined,
    deleteRow: undefined,
    search: undefined,
    multiColumnSearch: undefined,
    exportCSV: undefined,
    searchPlaceholder: undefined,
};

const removedDeprecatedOptions = {
    showSelectedOnlyBtn: undefined,
    exportCSVBtn: undefined,
    clearSearchBtn: undefined,
    clearSearch: undefined,
    defaultSearch: undefined,
    searchDelayTime: undefined,
    searchField: undefined,
    searchPanel: undefined,
    btnGroup: undefined,
    toolBar: undefined,
    searchPosition: undefined,
    afterSearch: undefined,
    afterInsertRow: undefined,
    afterDeleteRow: undefined,
    handleConfirmDeleteRow: undefined,
    exportCSVText: undefined,
    insertText: undefined,
    deleteText: undefined,
    saveText: undefined,
    closeText: undefined,
};

export default class RioBootstrapTable extends Component {

    /* istanbul ignore next */
    constructor(props, context) {
        super(props, context);

        const selectedElements = this.getSelectedElementsByKey(this.props);

        const sortState = this.getCurrentSortState(props);

        /* Note: tableHeaderSortOrder - We use 'undefined' here so that the original
         * implementation of react-bootstrap-table will control the sort order
         * (either 'asc' or 'desc') and render the caret appropriately. */
        this.state = {
            ...this.state,
            selectedElements: selectedElements,
            selectedElementsOld: [],
            renderData: this.props.data,
            tableHeaderSortOrder: undefined,
            headerColumnStrings: [],
            ...sortState,
        };

        this.sortFunc = this.sortFunc.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.customMultiSelect = this.customMultiSelect.bind(this);
        this.onSortChange = this.onSortChange.bind(this);

        this.tableSelectionBehavior = {
            className: 'active',
            columnWidth: '30px',
            onSelect: this.onSelect,
        };
    }

    componentDidMount() {
        this.setHeaderColumnStrings();
    }

    componentDidUpdate() {
        // only extract header strings when new children were passed in
        if (this.state.updateHeaderColumnString) {
            this.setHeaderColumnStrings();
        }
    }

    setHeaderColumnStrings() {
        const ths = ReactDOM.findDOMNode(this.bootstrapTableRef)
            .getElementsByClassName('react-bs-container-header')[0]
            .getElementsByTagName('th');

        const thStrings = [...ths].map(th => th.textContent.replace(/\r?\n|\r/g, ''));

        if (!isEqual(thStrings, this.state.headerColumnStrings)) {
            this.setState({ headerColumnStrings: thStrings });
        }
    }

    getCurrentSortState(props) {
        const { currentSortName, currentSortOrder } = this.state || {};
        const { options = {} } = props;

        const nextSortName = (options.defaultSortName && options.onSortChange) ?
            options.defaultSortName : currentSortName;

        const nextSortOrder = (options.defaultSortOrder && options.onSortChange) ?
            options.defaultSortOrder : currentSortOrder;

        return {
            currentSortName: nextSortName,
            currentSortOrder: nextSortOrder,
        };
    }

    getSelectedElementsByKey({ selectedElements = [], data = {}, keyField = '' }) {
        return selectedElements.filter(key => data.find(dataItem => isEqual(dataItem[keyField], key)));
    }

    componentWillMount() {
        const options = this.props.options;

        // trigger initial sorting in case it has been set externally
        if (options && options.defaultSortName && options.defaultSortOrder) {
            this.onSortChange(options.defaultSortName, options.defaultSortOrder);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (isEqual(this.props, nextProps)) {
            return;
        }

        const selectedElements = nextProps.selectedElements ?
            this.getSelectedElementsByKey(nextProps) : this.state.selectedElements;

        const sortState = this.getCurrentSortState(nextProps);

        const hasDataChanged = isEqual(this.props.data, nextProps.data);
        const haveChildrenChanged = isEqual(this.props.children, nextProps.children);

        this.setState({
            selectedElements,
            selectedElementsOld: this.props.selectedElements || this.state.selectedElementsOld,
            renderData: hasDataChanged ? this.state.renderData : nextProps.data,
            updateHeaderColumnString: haveChildrenChanged,
            ...sortState,
        });
    }

    createTableViewElements() {
        const { unSelectableElements = [], keyField } = this.props;
        const { selectedElements = [], renderData } = this.state;

        return renderData.map((element, index) => {
            return {
                ...element,
                originalElement: element,
                tableRowKey: index,
                isSelected: !!selectedElements.find(el => isEqual(el, element[keyField])),
                unselectable: !!unSelectableElements.find(el => isEqual(el, element[keyField])),
            };
        });
    }

    getTableSelectionBehaviour(tableViewElements) {
        const selectionBehavior = {
            ...this.tableSelectionBehavior,
            clickToSelect: this.props.clickToSelect,
            selected: tableViewElements
                .filter(element => element.isSelected)
                .map(filteredElement => filteredElement.tableRowKey),
            unselectable: tableViewElements
                .filter(element => element.unselectable)
                .map(filteredElement => filteredElement.tableRowKey),
        };

        if (this.props.singleSelect) {
            return {
                ...selectionBehavior,
                mode: 'radio',
                hideSelectColumn: true,
            };
        }

        return {
            ...selectionBehavior,
            onSelectAll: this.onSelectAll,
            mode: 'checkbox',
            customComponent: this.customMultiSelect,
        };
    }

    customMultiSelect(props) {
        const oldSelection = this.state.selectedElementsOld || [];
        const isNewSelected = (props.rowIndex === 'Header') ? false :
            oldSelection.findIndex((selectedElement) =>
                isEqual(selectedElement, this.state.renderData[props.rowIndex][this.props.keyField])
            ) === -1;

        return (
            <div className={`checkboxWrapper`}>
                <Checkbox {...props} className={isNewSelected ? '' : 'no-animation'} onClick={() => {}}/>
            </div>
        );
    }

    onSelect(row, isSelected, event) {
        // rejects on STOPPING - tags or classes
        if (event.type === 'click') {
            const hasWrongClass = STOPPING_CLASSES.some((className) => {
                event.target.classList.contains(className);
            });

            if (STOPPING_NODES.indexOf(event.target.nodeName) !== -1 || hasWrongClass || event.defaultPrevented) {
                return false;
            }
        }

        const selectedRow = { ...row };
        selectedRow.isSelected = isSelected;

        const selectedElementsOld = this.state.selectedElements || [];
        const selectedElementsNew = this.updateArrayOfSelectedElements([row], isSelected, selectedElementsOld);

        this.props.onSelectionChange(selectedElementsNew, selectedElementsOld);
    }

    onSelectAll(isSelected, rows) {
        const selectedRows = rows.map(row => ({ ...row, isSelected: isSelected }));
        const selectedElementsOld = [...this.state.selectedElements];
        const selectedElementsNew = this.updateArrayOfSelectedElements(selectedRows, isSelected, selectedElementsOld);

        this.props.onSelectionChange(selectedElementsNew, selectedElementsOld);
    }

    updateArrayOfSelectedElements(rows = [], isSelected, selectedElementsOld) {
        const { keyField, singleSelect } = this.props;

        let selectedElements = [...this.state.selectedElements];

        if (!selectedElements) {
            return [];
        }

        if (singleSelect) {
            selectedElements = [];
        }

        rows.forEach(row => {
            const index = selectedElements.findIndex(el => isEqual(el, row.originalElement[keyField]));
            if (isSelected) {
                if (index === -1) {
                    selectedElements.push(row.originalElement[keyField]);
                }
            } else if (index !== -1) {
                selectedElements.splice(index, 1);
            }
        });

        this.setState({
            selectedElements: this.props.selectedElements || selectedElements,
            selectedElementsOld: selectedElementsOld,
        });

        return selectedElements;
    }

    sortFunc(a, b, order, sortField/*, extraData*/) {
        const sortedData = this.doCustomSorting(sortField, order);
        const sortedFields = sortedData.map(dataEntry => dataEntry[sortField]);
        const indexA = sortedFields.indexOf(a[sortField]);
        const indexB = sortedFields.indexOf(b[sortField]);

        return indexA < indexB ? -1 : 1;
    }

    onSortChange(sortName, sortOrder) {
        const sortedData = this.doCustomSorting(sortName, sortOrder);
        this.setState({
            tableHeaderSortOrder: undefined,
            renderData: sortedData,
            currentSortName: sortName,
            currentSortOrder: sortOrder,
        });
    }

    doCustomSorting(sortName, sortOrder) {
        return this.props.customSort ?
            this.props.customSort(sortName, sortOrder) :
            sortByProperty(this.props.data, sortName, sortOrder, this.props.isCaseSensitiv);
    }

    getThClassName(headerColumn) {
        const { className } = headerColumn.props;

        const isSortingColumn = headerColumn.props.dataField === this.state.currentSortName;

        return classNames(
            isSortingColumn && `sorted-column ${this.state.currentSortOrder}`,
            className && className
        );
    }

    getCardSortingHeader(childrenToRender) {
        const headerOptions = childrenToRender.map((child, index) => {
            return {
                id: child.props.dataField || index.toString(),
                label: `${child.props.tdAttr['data-field']}`,
                selected: child.props.dataField === this.state.currentSortName,
                disabled: !child.props.dataSort,
            };
        });

        return (
            <TableCardsSorting
                selectOptions={headerOptions}
                sortName={this.state.currentSortName}
                sortOrder={this.state.currentSortOrder}
                onSortChange={this.onSortChange} />
        );
    }

    gerOrderedChildren(changedColumnOrder, children) {
        return changedColumnOrder.map(columnDataField => (
            children.find(child => child.props.dataField === columnDataField)
        ));
    }

    render() {
        const {
            singleCard,
            multiCards,
            singleSelect,
            enableRowSelection,
            changedColumnOrder,
            stickyPosition,
            stickyPositionOffset,
            options = {},
            children,
            tableHeaderClass,
            ...remainingProps
        } = this.props;

        const { renderData, currentSortName, currentSortOrder } = this.state;

        const cleanedChildren = compact(children);

        const orderedChildren = changedColumnOrder ?
            this.gerOrderedChildren(changedColumnOrder, cleanedChildren) : cleanedChildren;

        const tableViewElements = this.createTableViewElements();

        const tableHeaderClasses = classNames(
            singleCard && !multiCards && 'table-cards table-single-card',
            !singleCard && multiCards && 'table-cards table-multi-cards',
            tableHeaderClass && tableHeaderClass
        );

        const customProps = {
            ...remainingProps,
            data: tableViewElements,
            keyField: 'tableRowKey',
            tableHeaderClass: tableHeaderClasses,
            tableBodyClass: tableHeaderClasses,
            tableContainerClass: `rio-bs-table`,
            selectRow: (enableRowSelection && renderData.length) ?
                this.getTableSelectionBehaviour(tableViewElements) : undefined,
            options: {
                ...options,
                sortName: currentSortName,
                sortOrder: currentSortOrder,
                onSortChange: options && options.onSortChange ? options.onSortChange : this.onSortChange,
                ...removedDeprecatedOptions,
            },
            headerContainerClass: stickyPosition ? 'sticky-position' : '',
            headerStyle: { top: stickyPositionOffset },
            ...removedDeprecatedProps,
        };

        /* For some of the props of react-bootstrap-table default values are defined. Unfortunately, these default props
         * will be used in our special case where we want the render() method to render {orderedChildren}, and there
         * is no possibility to override these default props.
         * Therefore, we clone all child elements and set those properties with our needed values right here instead of
         * in the render() method of RioTableHeaderColumn where it is supposed to be. */
        const childrenToRender = React.Children.map(orderedChildren, (child, index) => {
            if (child.props.hidden) {
                return;
            }

            const tdAttr = {};
            const domColumnIndex = (enableRowSelection && !singleSelect) ? index + 1 : index;

            if (this.state.headerColumnStrings[domColumnIndex]) {
                tdAttr['data-field'] = this.state.headerColumnStrings[domColumnIndex];
            }

            return {
                ...child,
                props: {
                    ...child.props,
                    ref: index,
                    sortFunc: child.props.sortFunc || this.sortFunc,
                    sortOrder: this.state.tableHeaderSortOrder,
                    tdAttr: tdAttr,
                    dataFormat: child.props.dataFormat || (cell => cell),
                    className: this.getThClassName(child),
                    columnClassName: child.props.columnClassName,
                },
            };
        });

        const isSortingEnabled = childrenToRender.some(child => child.props.dataSort);
        const shouldHeaderBeShown = isSortingEnabled && (this.props.singleCard || this.props.multiCards);

        return (
            <div>
                {shouldHeaderBeShown && this.getCardSortingHeader(childrenToRender)}
                <BootstrapTable {...customProps} ref={node => (this.bootstrapTableRef = node)}>
                    {childrenToRender}
                </BootstrapTable>
            </div>
        );
    }

}

RioBootstrapTable.defaultProps = {
    searchPlaceholder: 'Search table ...',
    striped: false,
    hover: true,
    singleSelect: false,
    onSelectionChange: () => {},
    stickyPosition: false,
    options: {
        noDataText: 'No data found.',
    },
};

RioBootstrapTable.propTypes = {
    ...BootstrapTable.propTypes,
    selectedElements: PropTypes.array,
    unSelectableElements: PropTypes.array,
    enableRowSelection: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    clickToSelect: PropTypes.bool,
    customSort: PropTypes.func,
    isCaseSensitiv: PropTypes.bool,
    singleSelect: PropTypes.bool,
    keyField: PropTypes.string.isRequired,
    striped: PropTypes.bool,
    hover: PropTypes.bool,
    stickyPosition: PropTypes.bool,
    stickyPositionOffset: PropTypes.string,
    // FIXME: why are there single AND multiCards properties when they are mutual exclusive
    singleCard: PropTypes.bool,
    multiCards: PropTypes.bool,
    changedColumnOrder: PropTypes.array,
};
