import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NumberInput from '../numberInput/NumberInput';

const INITIAL_TICK = 200;
const TICK_TIME = 50;

export default class NumberControl extends Component {

    constructor() {
        super();

        /* istanbul ignore next */
        this.isHolding = false;
        this.timeOutFunction = undefined;
    }

    increment() {
        /* istanbul ignore next */
        if (this.isHolding && !this.props.disabled) {
            this.numberInput.incrementValue();
            this.timeOutFunction = setTimeout(() => {
                this.increment();
            }, TICK_TIME);
        }
    }

    decrement() {
        /* istanbul ignore next */
        if (this.isHolding && !this.props.disabled) {
            this.numberInput.decrementValue();
            this.timeOutFunction = setTimeout(() => {
                this.decrement();
            }, TICK_TIME);
        }
    }

    onIncrement() {
        if (!this.props.disabled) {
            this.isHolding = true;
            this.numberInput.incrementValue();

            clearTimeout(this.timeOutFunction);
            this.timeOutFunction = setTimeout(() => {
                this.increment();
            }, INITIAL_TICK);
        }
    }

    onDecrement() {
        if (!this.props.disabled) {
            this.isHolding = true;
            this.numberInput.decrementValue();

            clearTimeout(this.timeOutFunction);
            this.timeOutFunction = setTimeout(() => {
                this.decrement();
            }, INITIAL_TICK);
        }
    }

    stopHolding() {
        this.isHolding = false;
    }

    render() {
        const { min, max, value, step, onValueChanged, disabled, size, bsSize, className, unit } = this.props;

        if (size) {
            /* eslint-disable no-console */
            console.warn('Prop "size" is deprecated on NumberControl. Please use "bsSize" instead.');
        }

        const classNames = classnames(
            'NumberControl',
            'form-group',
            className);
        const inputGroupClassNames = classnames(
            'input-group',
            size === 'small' && 'input-group-sm',
            size === 'large' && 'input-group-lg',
            bsSize === 'sm' && 'input-group-sm',
            bsSize === 'lg' && 'input-group-lg');
        return (
            <div className={classNames}>
                <span className={inputGroupClassNames}>
                    <span className='input-group-btn'>
                       <button type='button'
                            className={classnames('decrementButton', 'btn', 'btn-default', 'btn-icon-only',
                                disabled && 'disabled')}
                            onMouseDown={() => this.onDecrement()} onMouseUp={() => this.stopHolding()}>
                            <span className='rioglyph rioglyph-minus' aria-hidden='true'></span>
                        </button>
                    </span>
                    <NumberInput min={min} max={max} value={value} step={step} disabled={disabled}
                        onValueChanged={onValueChanged} ref={(child) => {
                            this.numberInput = child;
                        }}/>
                    { unit && <div className={'input-group-addon'}>{ unit }</div> }
                    <span className='input-group-btn'>
                        <button type='button'
                            className={classnames('incrementButton', 'btn', 'btn-default', 'btn-icon-only',
                                disabled && 'disabled')}
                            onMouseDown={() => this.onIncrement()} onMouseUp={() => this.stopHolding()}>
                            <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>
                        </button>
                    </span>
                </span>
            </div>
        );
    }
}

NumberControl.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    onValueChanged: PropTypes.func,
    size: PropTypes.string,
    bsSize: PropTypes.string,
    className: PropTypes.string,
    unit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};
