import React from 'react';
import PropTypes from 'prop-types';

export const RATING_OK = 'HIGH';
export const RATING_WARNING = 'MEDIUM';
export const RATING_EXCEPTION = 'LOW';

export function getVehicleRatingClassName(ratingType) {
    switch (ratingType) {
        case RATING_EXCEPTION:
            return 'VehicleRatingCounterLow';
        case RATING_WARNING:
            return 'VehicleRatingCounterMedium';
        case RATING_OK:
        default:
            return 'VehicleRatingCounterHigh';
    }
}

export const VehicleRatingCounter = ({ total, ratingType }) => (
    <div className='VehicleRatingCounter'>
        <div className={getVehicleRatingClassName(ratingType)}>{total}</div>
    </div>
);

VehicleRatingCounter.propTypes = {
    total: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]).isRequired,
    ratingType: PropTypes.oneOf([RATING_OK, RATING_WARNING, RATING_EXCEPTION]),
};

export default VehicleRatingCounter;
