/**
 * @fileOverview: Presentational component for a rated marker on dashboard map.
 */

import React from 'react';
import PropTypes from 'prop-types';

export const STATE_WARNING = 'STATE_WARNING';
export const STATE_EXCEPTION = 'STATE_EXCEPTION';

function getCssClassByRating(vehicleState) {
    switch (vehicleState) {
        case STATE_WARNING:
            return 'rating-warning';
        case STATE_EXCEPTION:
            return 'rating-exception';
        default:
            return 'rating-ok';
    }
}

function renderArrow(bearing, cssClass) {
    if (!bearing && bearing !== 0) {
        return '';
    }

    const angle = bearing % 360;
    return (
        <g transform='translate(50.5,23.5)'>
            <polygon transform={`rotate(${angle})`}
                 className={`${cssClass} arrow`}
                 points='10.04,12.12 0,-12.12 -10.04,12.12 0,3.86'/>
        </g>
    );
}

function renderArrowRect() {
    return <rect className='innerBox' x='33' y='6' width='35' height='35'/>;
}

/*
 * Presentational component for a rated marker on dashboard map.
 */
export default function VehicleMarker(props) {
    // eslint-disable-next-line no-console
    console.warn(
        'VehicleMarker is deprecated and will be removed again. ' +
        'Please use SingleMapMarker or ClusterMapMarker to remove this warning.'
    );

    const cssClass = getCssClassByRating(props.vehicleState);

    const className = `VehicleMarker M${props.vehicleId}${props.clicked ? ' selected' : ''}`;

    const path = !props.clicked ? 'M27,0V48H75V0ZM33,6H69V41H33V6Z' : 'M27,0V48H75V0Z';

    return (
        <svg className={className} viewBox='0 0 100 100'>
            {props.clicked ? <circle className={`${cssClass} circle`} cx='50' cy='76.11' r='23.08'/> : ''}
            <circle className={`${cssClass} innerCircle`} cx='50'
                cy='76.11' r='5.38'/>
            <polygon className={`${cssClass}`}
                points='50 69.5 63.85 43.35 36.15 43.35 50 69.5'/>
            <path className={`${cssClass}`} d={path}/>
            {!props.clicked ? renderArrowRect() : null}
            {renderArrow(props.bearing, cssClass)}
        </svg>
    );
}

VehicleMarker.propTypes = {
    bearing: PropTypes.number,
    clicked: PropTypes.bool,
    vehicleId: PropTypes.number,
    vehicleState: PropTypes.oneOf([STATE_EXCEPTION, STATE_WARNING]),
};
