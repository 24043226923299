import React from 'react';
import PropTypes from 'prop-types';

const Spinner = (props) => {
    const { isInverse, isDoubleSized, isFullscreen } = props;

    const classNames = `spinner${isInverse ? ' inverse' : ''}${isDoubleSized ? ' double' : ''}`;

    if (isFullscreen) {
        return (
            <div className='height-100vh display-flex justify-content-center align-items-center'>
                <div className={'spinner double'} />
            </div>
        );
    }

    return (
        <div className={classNames} />
    );
};

Spinner.defaultProps = {
    isInverse: false,
    isDoubleSized: false,
    isFullscreen: false,
};

Spinner.propTypes = {
    isInverse: PropTypes.bool,
    isDoubleSized: PropTypes.bool,
    isFullscreen: PropTypes.bool,
};

export default Spinner;
