import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';

const SpinnerInfoBox = (props) => {
    const { text, isInverse } = props;

    return (
        <div className={`spinnerInfoBox${isInverse ? ' inverse' : ''}`}>
            <div className='spinnerIcon'>
                <Spinner isInverse={isInverse}/>
            </div>
            <div className='spinnerText'>
                {text}
            </div>
        </div>
    );
};

SpinnerInfoBox.defaultProps = {
    isInverse: false,
};

SpinnerInfoBox.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    isInverse: PropTypes.bool,
};

export default SpinnerInfoBox;
