import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox/Checkbox';

const TreeSelectAll = props => {
    const { isDisabled, isChecked, isEnabled, onSelect } = props;

    const handleSelectAll = event => onSelect(event.target.checked);

    return isEnabled ? (
        <div className={'TreeSelectAll display-flex align-items-center padding-right-5'}>
            <Checkbox checked={isChecked} onClick={handleSelectAll} disabled={isDisabled} />
        </div>
    ) : null;
};

TreeSelectAll.displayName = 'TreeSelectAll';

TreeSelectAll.defaultProps = {
    isChecked: false,
    isEnabled: true,
    hasSearchResults: false,
    onChange: () => {},
};

TreeSelectAll.propTypes = {
    value: PropTypes.string,
    onSelect: PropTypes.func,
    isChecked: PropTypes.bool,
    isEnabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    hasSearchResults: PropTypes.bool,
    className: PropTypes.string,
};

export default TreeSelectAll;
