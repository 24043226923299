import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { SortArrows } from './SortArrows';

const noCaretRender = () => ('');

export default class RioTableHeaderColumn extends Component {
    render() {
        const customProps = {
            ...this.props,
            caretRender: this.props.caretRender || noCaretRender,
            sort: this.props.sortOrder || this.props.sort,
        };

        const renderChildren = (this.props.caretRender) ? this.props.children :
            (<span>
                {<SortArrows direction={this.props.sort} />}
                {this.props.children}
            </span>);

        return (
            <TableHeaderColumn {...customProps}>
                {renderChildren}
            </TableHeaderColumn>
        );
    }
}

RioTableHeaderColumn.propTypes = {
    ...TableHeaderColumn.propTypes,
    sortOrder: PropTypes.string,
};
