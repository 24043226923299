import React from 'react';
import PropTypes from 'prop-types';
import DropdownSubmenu from '../dropdown/DropdownSubmenu';
import classNames from 'classnames';

const MenuItem = (props) => {
    const { value, divider, disabled, active, onSelect, header, index, closeMenu, onMouseEnter } = props;

    if (value && value.type === DropdownSubmenu) {
        return (value);
    }

    let role = divider ? 'separator' : 'presentation';
    if (header) {
        role = 'heading';
    }

    const classes = classNames(
        divider && 'divider pointer-events-none',
        disabled && 'disabled',
        header && 'dropdown-header',
        active && 'active'
    );

    const menuLink = (
        <a role='menuitem'>
            {value}
        </a>
    );

    const onSelectItem = (event) => {
        if (!disabled) {
            onSelect(index, event);

            if (closeMenu) {
                closeMenu();
            }
        }
    };

    const onItemMouseEnter = (event) => {
        if (!disabled) {
            onMouseEnter(event);
        }
    };

    return (
        <li role={role} className={classes} onClick={onSelectItem} onMouseEnter={onItemMouseEnter}
            data-item-index={index}>
            {header ? value : menuLink}
        </li>
    );
};

MenuItem.defaultProps = {
    disabled: false,
    onSelect: () => {},
    closeMenu: () => {},
    onMouseEnter: () => {},
};

MenuItem.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    divider: PropTypes.bool,
    header: PropTypes.bool,
    onSelect: PropTypes.func,
    index: PropTypes.number,
    closeMenu: PropTypes.func,
    onMouseEnter: PropTypes.func,
};

export default MenuItem;
