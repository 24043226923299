/**
 * @fileOverview: Presentational component for the info popup for a rated
 * vehicle (marker).
 */

import React from 'react';
import PropTypes from 'prop-types';

import VehicleCategory from '../vehicleCategory/VehicleCategory';

function renderVehicleRatingCounter(count, styleClass) {
    if (count) {
        return (
            <div className='RatingCounter'>
                <div className={styleClass}>{count}</div>
            </div>
        );
    }
}

/*
 * Presentational component for the info popup for a rated vehicle (marker).
 */
export default function MarkerTooltip(props) {
    /* eslint-disable no-console */
    console.warn(
        'MarkerTooltip is deprecated and will be removed again. ' +
        'Please use SingleMapMarker or ClusterMapMarker to remove this warning.'
    );

    const className = props.className ? props.className : '';
    return (
        <div className='clearfix'>
            <div className={`MarkerTooltip ${props.renderLeft ? 'left' : 'right'} ${className}`}>
                <div className='vehicleIconAndName'>
                    <div className='vehicleIconWrapper'>
                        <VehicleCategory category={props.vehicle.category} styleClasses={'vehicleIcon'}/>
                    </div>
                    <div className='vehicleName'>{props.vehicle.name || 'unknown'}</div>
                </div>
                <div className={`ratings${props.renderLeft ? ' left' : ' right'}`}>
                    {renderVehicleRatingCounter(props.exceptionCount, 'VehicleRatingCounterLow')}
                    {renderVehicleRatingCounter(props.warningCount, 'VehicleRatingCounterMedium')}
                </div>
            </div>
        </div>
    );
}

MarkerTooltip.propTypes = {
    className: PropTypes.string,
    renderLeft: PropTypes.bool,
    vehicle: PropTypes.object.isRequired,
    warningCount: PropTypes.number,
    exceptionCount: PropTypes.number,
};
