import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const Tag = (props) => {
    const { icon, active, disabled, clickable, selectable, deletable, muted, ...remainingProps } = props;

    return (
        <div
            {...remainingProps}
            className={
                classname(
                    'tag',
                    (props.size === 'small') && 'tag-small',
                    icon && `rioglyph ${icon}`,
                    active && 'active clickable rioglyph rioglyph-ok',
                    disabled && 'disabled',
                    clickable && 'clickable',
                    selectable && 'selectable clickable rioglyph rioglyph-plus',
                    deletable && 'deletable clickable rioglyph rioglyph-remove',
                    muted && 'tag-muted',
                    props.className
                )
            }
        />
    );
};

Tag.defaultProps = {
    active: false,
    clickable: false,
    selectable: false,
    deletable: false,
    disabled: false,
    muted: false,
};

Tag.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small']),
    icon: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
    selectable: PropTypes.bool,
    deletable: PropTypes.bool,
    muted: PropTypes.bool,
};

export default Tag;
