import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.onChange = this.onChange.bind(this);
    }

    isValidDate(date) {
        return typeof date === 'object';
    }

    onChange(date) {
        const isValid = this.isValidDate(date);
        this.setState({ hasError: !isValid });
        this.props.onChange(date, isValid);
    }

    render() {
        const { className, dropup } = this.props;
        const { hasError } = this.state;

        const classes = classNames(
            'DatePicker',
            className && className,
            hasError && 'has-error',
            dropup && 'dropup'
        );

        return (
            <Datetime
                ref={(node) => (this.refDatePicker = node)}
                {...this.props}
                onChange={this.onChange}
                className={classes}
            />
        );
    }
}

DatePicker.defaultProps = {
    closeOnSelect: true,
    dropup: false,
    defaultValue: moment(),
    locale: 'en-GB',
    onChange: () => {},
};

DatePicker.propTypes = {
    className: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    defaultValue: PropTypes.object,
    locale: PropTypes.string,
    onChange: PropTypes.func,
    dropup: PropTypes.bool,
};

export default DatePicker;
