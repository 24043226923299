import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/fp/throttle';
import ReactDOM from 'react-dom';
import { OverlayTrigger } from 'react-bootstrap';
import classnames from 'classnames';

import addEventListener from '../../utils/addEventListener';
import Tooltip from '../tooltip/Tooltip';

const DELAY_SHOW = 500;
const DELAY_HIDE = 300;
const RESIZE_THROTTELING = 10;

// FIXME: used in a service it will fail to update position once the tooltip was hidden and shown again

export default class OnboardingTip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
        this.handleScroll = throttle(RESIZE_THROTTELING)(this.handleScroll.bind(this));
    }

    componentDidMount() {
        this.registerScrollEvent();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.show && !nextProps.show) {
            this.cleanupListener();
            this.setState(() => ({
                tooltipNode: null,
            }));
        }

        if (!this.props.show && nextProps.show) {
            this.registerScrollEvent();
        }
    }

    componentDidUpdate() {
        this.init();
        this.saveInitialPosition();
    }

    registerScrollEvent() {
        const { scrollTargetRef } = this.props;

        // Note: scroll listener has to be registered on "module-content" since we do not scroll the entire document but
        // the module content area. This means, placing an OnboardingTip in a Sidebar we need to add this reference here
        const ref = scrollTargetRef || this.getModuleContentRef();
        this.onScrollListener = addEventListener(ref, 'scroll', this.handleScroll);
    }

    init() {
        const { scrollTargetRef } = this.props;
        const { tooltipNode } = this.state;

        if (this.tooltipRef && !tooltipNode) {
            const ref = scrollTargetRef || this.getModuleContentRef();
            const node = ReactDOM.findDOMNode(this.tooltipRef);
            const tooltip = node.parentNode.parentNode;

            this.setState(() => ({
                tooltipNode: tooltip,
                scrollingNode: ref,
            }));
        }
    }

    saveInitialPosition() {
        const { tooltipNode, initialTooltipPosition } = this.state;

        if (!initialTooltipPosition && tooltipNode) {
            const top = parseInt(tooltipNode.style.top.replace('px', ''), 10);
            const left = parseInt(tooltipNode.style.left.replace('px', ''), 10);

            this.setState(() => ({
                initialTooltipPosition: { top, left },
            }));
        }
    }

    getModuleContentRef() {
        const [moduleContent] = document.getElementsByClassName('module-content');
        return moduleContent;
    }

    cleanupListener() {
        if (this.onScrollListener) {
            this.onScrollListener.remove();
        }
    }

    componentWillUnmount() {
        this.cleanupListener();
    }

    handleScroll() {
        const { onHide, hideOnScroll } = this.props;
        const { tooltipNode, initialTooltipPosition, scrollingNode } = this.state;

        if (!tooltipNode) {
            this.init();
            return;
        }

        if (!initialTooltipPosition && tooltipNode) {
            this.saveInitialPosition();
            return;
        }

        if (scrollingNode && tooltipNode) {
            tooltipNode.style.top = `${initialTooltipPosition.top - scrollingNode.scrollTop}px`;
        }

        if (hideOnScroll) {
            onHide();
            this.cleanupListener();
        }
    }

    render() {
        const {
            id,
            show,
            placement,
            title,
            content,
            onHide,
            children,
            textAlignment,
            useInDialog,
            showCloseIcon,
            className,
            width,
        } = this.props;

        // const { show } = this.state;

        const tooltipWrapperClasses = classnames(
            placement && placement,
            useInDialog && 'z-index-max',
            className && className
        );

        const overlay = (
            <Tooltip
                className={tooltipWrapperClasses}
                tooltipStyle={Tooltip.STYLE_ONBOARDING}
                id={id}
                onClick={onHide}
                width={width}
                textAlignment={textAlignment}
            >
                <div className={'display-flex'} ref={node => (this.tooltipRef = node)}>
                    <div className={'display-flex flex-column flex-1-1'}>
                        {title && <div className={'tooltip-title'}>{title}</div>}
                        {content && <div className={'tooltip-content'}>{content}</div>}
                    </div>
                    {showCloseIcon &&
                        <span className={'tooltip-close rioglyph rioglyph-remove'}></span>
                    }
                </div>
            </Tooltip>
        );

        const [overlayTriggerPlacement] = placement.split('-');

        const trigger = (
            <OverlayTrigger
                placement={overlayTriggerPlacement}
                trigger={'click'}
                overlay={overlay}
                delayShow={DELAY_SHOW}
                delayHide={DELAY_HIDE}
                onClick={onHide}
                defaultOverlayShown={show}
            >
                {children}
            </OverlayTrigger>
        );

        return show ? trigger : children;
    }
}

OnboardingTip.PLACEMENT_TOP_LEFT = Tooltip.PLACEMENT_TOP_LEFT;
OnboardingTip.PLACEMENT_TOP = Tooltip.PLACEMENT_TOP;
OnboardingTip.PLACEMENT_TOP_RIGHT = Tooltip.PLACEMENT_TOP_RIGHT;
OnboardingTip.PLACEMENT_RIGHT_TOP = Tooltip.PLACEMENT_RIGHT_TOP;
OnboardingTip.PLACEMENT_RIGHT = Tooltip.PLACEMENT_RIGHT;
OnboardingTip.PLACEMENT_RIGHT_BOTTOM = Tooltip.PLACEMENT_RIGHT_BOTTOM;
OnboardingTip.PLACEMENT_BOTTOM_RIGHT = Tooltip.PLACEMENT_BOTTOM_RIGHT;
OnboardingTip.PLACEMENT_BOTTOM = Tooltip.PLACEMENT_BOTTOM;
OnboardingTip.PLACEMENT_BOTTOM_LEFT = Tooltip.PLACEMENT_BOTTOM_LEFT;
OnboardingTip.PLACEMENT_LEFT_BOTTOM = Tooltip.PLACEMENT_LEFT_BOTTOM;
OnboardingTip.PLACEMENT_LEFT = Tooltip.PLACEMENT_LEFT;
OnboardingTip.PLACEMENT_LEFT_TOP = Tooltip.PLACEMENT_LEFT_TOP;

OnboardingTip.TEXT_ALIGNMENT_LEFT = 'left';
OnboardingTip.TEXT_ALIGNMENT_CENTER = 'center';
OnboardingTip.TEXT_ALIGNMENT_RIGHT = 'right';

OnboardingTip.defaultProps = {
    show: false,
    onHide: () => {},
    showCloseIcon: true,
    hideOnScroll: false,
    textAlignment: OnboardingTip.TEXT_ALIGNMENT_LEFT,
    useInDialog: false,
    className: '',
    placement: OnboardingTip.PLACEMENT_BOTTOM,
    width: null,
};

OnboardingTip.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    show: PropTypes.bool.isRequired,
    showCloseIcon: PropTypes.bool,
    textAlignment: PropTypes.oneOf([
        OnboardingTip.TEXT_ALIGNMENT_LEFT,
        OnboardingTip.TEXT_ALIGNMENT_CENTER,
        OnboardingTip.TEXT_ALIGNMENT_RIGHT,
    ]),
    onHide: PropTypes.func.isRequired,
    scrollTargetRef: PropTypes.node,
    hideOnScroll: PropTypes.bool,
    useInDialog: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    placement: PropTypes.oneOf([
        OnboardingTip.PLACEMENT_TOP_LEFT,
        OnboardingTip.PLACEMENT_TOP,
        OnboardingTip.PLACEMENT_TOP_RIGHT,
        OnboardingTip.PLACEMENT_RIGHT_TOP,
        OnboardingTip.PLACEMENT_RIGHT,
        OnboardingTip.PLACEMENT_RIGHT_BOTTOM,
        OnboardingTip.PLACEMENT_BOTTOM_RIGHT,
        OnboardingTip.PLACEMENT_BOTTOM,
        OnboardingTip.PLACEMENT_BOTTOM_LEFT,
        OnboardingTip.PLACEMENT_LEFT_BOTTOM,
        OnboardingTip.PLACEMENT_LEFT,
        OnboardingTip.PLACEMENT_LEFT_TOP,
    ]),
    width: PropTypes.oneOf(['auto', 100, 150, 200, 250, 300, 350, 400, 450, 500]),
};
