import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import { baseStatePropTypes, ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT } from './baseStatePropTypes';

const StateButton = ({ text, className, onClick = () => {}, href }) => {
    const buttonClassNames = classNames(
        'btn btn-primary',
        'margin-top-10',
        className && className
    );

    // In case a href is given, render a link button instead
    if (href) {
        return (
            <a className={buttonClassNames} href={href} onClick={onClick}>
                {text}
            </a>
        );
    }

    return (
        <button className={buttonClassNames} type='button' onClick={onClick}>
            {text}
        </button>
    );
};

const Icon = ({ name, className }) => {
    const iconClassNames = classNames(
        'rioglyph',
        'text-color-light',
        name && name,
        !className && 'text-size-300pct',
        className && className
    );
    return (
        <span className={iconClassNames}></span>
    );
};

const getAlignmentClassName = (alignment) => {
    switch (alignment) {
        case ALIGN_LEFT:
            return 'justify-content-start';
        case ALIGN_CENTER:
            return 'justify-content-center';
        case ALIGN_RIGHT:
            return 'justify-content-end';
        default:
            return 'justify-content-center';
    }
};

const CustomState = (props) => {
    const { icon, icons, image, headline, message, buttons, alignment, fullWidth, className } = props;

    const wrapperClasses = classNames(
        'margin-0',
        'width-100pct',
        !fullWidth && 'max-width-600',
        'panel-default',
        'panel',
        'panel-body'
    );

    const innerClassNames = classNames(
        'display-flex',
        'flex-column',
        'align-items-center',
        'text-center',
        'padding-25',
        className && className
    );

    const renderButtons = (
        <div className='btn-toolbar'>
            {buttons.map((buttonProps, index) => <StateButton key={index} {...buttonProps} />)}
        </div>
    );

    const renderIcon = (
        <div className='text-size-h2 margin-bottom-20'>
            <Icon name={icon} />
        </div>
    );

    const renderIcons = (
        <div className={classNames(
            'text-size-h2',
            'margin-bottom-20',
            'display-flex',
            'align-items-center',
            'max-width-100pct')}>
            {icons.map((iconProps, index) => <Icon key={index} {...iconProps} />)}
        </div>
    );

    const renderHeadline = (
        <div className={classNames(
            'text-size-h3',
            'text-size-h2-sm',
            'text-color-dark',
            'text-medium',
            'width-500',
            'max-width-100pct')}>
            {headline}
        </div>
    );

    const renderMessage = (
        <div className='text-color-dark margin-top-15 margin-bottom-20 width-500 max-width-100pct'>
            {message}
        </div>
    );

    const singleOrMultiIcon = isEmpty(icons) ? renderIcon : renderIcons;
    const imageOrIcon = image ? image : singleOrMultiIcon;

    return (
        <div className={`display-flex ${getAlignmentClassName(alignment)}`}>
            <div className={wrapperClasses}>
                <div className={innerClassNames}>
                    {imageOrIcon}
                    {headline && renderHeadline}
                    {message && renderMessage}
                    {!isEmpty(buttons) && renderButtons}
                </div>
            </div>
        </div>
    );
};

CustomState.defaultProps = {
    icon: 'rioglyph-rio',
    icons: [],
    buttons: [],
    alignment: ALIGN_CENTER,
    fullWidth: false,
};

CustomState.propTypes = baseStatePropTypes;

export default CustomState;
