import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutFooter = ({ className, children }) => {
    const classes = classNames('ApplicationLayoutFooter', className);

    return (
        <footer className={classes}>
            {children}
        </footer>
    );
};

ApplicationLayoutFooter.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayoutFooter;
