import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusIcon from './busIcon';
import VanIcon from './vanIcon';
import TruckIcon from './truckIcon';

// defines the vehicle category for truck
export const CATEGORY_TRUCK = 'TRUCK';

// defines the vehicle category for bus
export const CATEGORY_BUS = 'BUS';

// defines the vehicle category for van
export const CATEGORY_VAN = 'VAN';

function getIcon(category) {
    switch (category) {
        case CATEGORY_BUS:
            return BusIcon;
        case CATEGORY_VAN:
            return VanIcon;
        case CATEGORY_TRUCK:
        default:
            return TruckIcon;
    }
}

const VehicleCategory = (props) => {

    const { styleClasses, labeled, text, size } = props;
    const iconStyle = classNames('iconItem', styleClasses, size && 'large');
    const iconLabelStyle = classNames('iconLabel', size && 'large');
    const category = props.category || CATEGORY_TRUCK;

    const CategoryIcon = getIcon(category);

    return (
        <span className='VehicleCategory'>
            <span className='iconWrapper'><CategoryIcon className={iconStyle}/></span>
            {labeled && <span className={iconLabelStyle}>{text || category}</span>}
        </span>
    );
};

VehicleCategory.defaultProps = {
    labeled: false,
};

VehicleCategory.propTypes = {
    category: PropTypes.oneOf([CATEGORY_BUS, CATEGORY_VAN, CATEGORY_TRUCK]),
    styleClasses: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.object,
    ]),
    size: PropTypes.oneOf(['large']),
    labeled: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

export default VehicleCategory;
