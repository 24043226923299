import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

export const patchReactForReactBootstrap = (React) => {

    if (!React.hasOwnProperty('createClass')) {
        React.createClass = createReactClass;
    }

    if (!React.hasOwnProperty('PropTypes')) {
        React.PropTypes = PropTypes;
    }

};
