/**
 * @fileOverview: Presentational component for a formatted driver name.
 */

import React from 'react';
import PropTypes from 'prop-types';

export const formatDriverName = ({ firstName, lastName }) => {
    return lastName && firstName ? [lastName, firstName].join(', ') : lastName;
};

/**
 * Presentational component for a formatted driver name.
 */

export const DriverName = ({ driver }) => (
    <span className='DriverName'>{formatDriverName(driver)}</span>
);

DriverName.propTypes = {
    driver: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string.isRequired,
    }),
};

export default DriverName;
